export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const EnerEchartsBar = () => import('../../components/enerEcharts/enerEchartsBar.vue' /* webpackChunkName: "components/ener-echarts-bar" */).then(c => wrapFunctional(c.default || c))
export const EnerEchartsLine = () => import('../../components/enerEcharts/enerEchartsLine.vue' /* webpackChunkName: "components/ener-echarts-line" */).then(c => wrapFunctional(c.default || c))
export const EnerEchartsMap = () => import('../../components/enerEcharts/enerEchartsMap.vue' /* webpackChunkName: "components/ener-echarts-map" */).then(c => wrapFunctional(c.default || c))
export const EnerEchartsPie = () => import('../../components/enerEcharts/enerEchartsPie.vue' /* webpackChunkName: "components/ener-echarts-pie" */).then(c => wrapFunctional(c.default || c))
export const HomeKeyFigureBlock = () => import('../../components/home/KeyFigureBlock.vue' /* webpackChunkName: "components/home-key-figure-block" */).then(c => wrapFunctional(c.default || c))
export const PageBenchmarkBuilder = () => import('../../components/page/benchmarkBuilder.vue' /* webpackChunkName: "components/page-benchmark-builder" */).then(c => wrapFunctional(c.default || c))
export const PageChartContainer = () => import('../../components/page/chartContainer.vue' /* webpackChunkName: "components/page-chart-container" */).then(c => wrapFunctional(c.default || c))
export const PageEnerRank = () => import('../../components/page/enerRank.vue' /* webpackChunkName: "components/page-ener-rank" */).then(c => wrapFunctional(c.default || c))
export const PageForesightChartContainer = () => import('../../components/page/foresightChartContainer.vue' /* webpackChunkName: "components/page-foresight-chart-container" */).then(c => wrapFunctional(c.default || c))
export const PageMapTool = () => import('../../components/page/mapTool.vue' /* webpackChunkName: "components/page-map-tool" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooterColumn = () => import('../../components/layout/FooterColumn.vue' /* webpackChunkName: "components/layout-footer-column" */).then(c => wrapFunctional(c.default || c))
export const LayoutNewsBlock = () => import('../../components/layout/NewsBlock.vue' /* webpackChunkName: "components/layout-news-block" */).then(c => wrapFunctional(c.default || c))
export const LayoutNewsLine = () => import('../../components/layout/NewsLine.vue' /* webpackChunkName: "components/layout-news-line" */).then(c => wrapFunctional(c.default || c))
export const LayoutPublicationBlock = () => import('../../components/layout/PublicationBlock.vue' /* webpackChunkName: "components/layout-publication-block" */).then(c => wrapFunctional(c.default || c))
export const UiEnerDownloadFloatingButton = () => import('../../components/ui/enerDownloadFloatingButton.vue' /* webpackChunkName: "components/ui-ener-download-floating-button" */).then(c => wrapFunctional(c.default || c))
export const UiEnerLoader = () => import('../../components/ui/enerLoader.vue' /* webpackChunkName: "components/ui-ener-loader" */).then(c => wrapFunctional(c.default || c))
export const UiEnerMobileMenu = () => import('../../components/ui/enerMobileMenu.vue' /* webpackChunkName: "components/ui-ener-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const UiEnerSocialFloatingButton = () => import('../../components/ui/enerSocialFloatingButton.vue' /* webpackChunkName: "components/ui-ener-social-floating-button" */).then(c => wrapFunctional(c.default || c))
export const UiEnerTopMenu = () => import('../../components/ui/enerTopMenu.vue' /* webpackChunkName: "components/ui-ener-top-menu" */).then(c => wrapFunctional(c.default || c))
export const UiLoginForm = () => import('../../components/ui/loginForm.vue' /* webpackChunkName: "components/ui-login-form" */).then(c => wrapFunctional(c.default || c))
export const UiPasswordForm = () => import('../../components/ui/passwordForm.vue' /* webpackChunkName: "components/ui-password-form" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
